// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { attachLogger } from 'effector-logger';
///////////////////////////////////////////////////////////////////////////////////
// import { attachReduxDevTools } from '@effector/redux-devtools-adapter';
import ReactDOM from 'react-dom/client';

import App from './App';

// attachLogger();

// attachReduxDevTools({
//   name: 'devmode',
// });

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
